var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.condition,
          expression: "condition",
        },
      ],
      staticStyle: {
        margin: "auto",
        background: "rgba(255, 255, 255, 0)",
        display: "block",
        "shape-rendering": "auto",
      },
      attrs: {
        width: "30px",
        height: "30px",
        viewBox: "0 0 100 100",
        preserveAspectRatio: "xMidYMid",
      },
    },
    [
      _c(
        "circle",
        {
          attrs: {
            cx: "50",
            cy: "50",
            r: "27",
            "stroke-width": "7",
            stroke: "#171717",
            "stroke-dasharray": "42.411500823462205 42.411500823462205",
            fill: "none",
            "stroke-linecap": "round",
          },
        },
        [
          _c("animateTransform", {
            attrs: {
              attributeName: "transform",
              type: "rotate",
              repeatCount: "indefinite",
              dur: "1.282051282051282s",
              keyTimes: "0;1",
              values: "0 50 50;360 50 50",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }