<template>
   <svg
      style="
         margin: auto;
         background: rgba(255, 255, 255, 0);
         display: block;
         shape-rendering: auto;
      "
      width="30px"
      height="30px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      v-show="condition"
   >
      <circle
         cx="50"
         cy="50"
         r="27"
         stroke-width="7"
         stroke="#171717"
         stroke-dasharray="42.411500823462205 42.411500823462205"
         fill="none"
         stroke-linecap="round"
      >
         <animateTransform
            attributeName="transform"
            type="rotate"
            repeatCount="indefinite"
            dur="1.282051282051282s"
            keyTimes="0;1"
            values="0 50 50;360 50 50"
         ></animateTransform>
      </circle>
   </svg>
</template>

<script>
export default {
   name: "UILoadingIcon_B",

   props: ["condition"],
};
</script>
